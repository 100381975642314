import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { Card } from 'components/Containers';
import { Icon } from 'components/Icon';

import { largeMobileMQ, smallTabletMQ } from 'styles/breakpoints';

import { x } from 'assets/icons';

const ModalContent = styled.div<{ minWidth?: number }>`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 100%;
  max-height: 100%;
  min-width: ${({ minWidth }) => minWidth ? minWidth : '300'}px;
  ${({ minWidth }) => minWidth === 200 && 'max-width: 500px;'}

  ${smallTabletMQ('min-width: 100% !important')};
`;

const ModalTitle = styled.h2`
`;

const fadeInBackground = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
`;

const fadeOutBackground = keyframes`
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0;
  }
`;

const Greyed = styled.div<{
  isOpen: boolean;
  isUnderneath: boolean;
}>`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: ${({ isUnderneath }) => isUnderneath ? '0' : '0.75'};
  z-index: 101;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  animation: ${({ isOpen }) => isOpen ? fadeInBackground : fadeOutBackground} 0.3s linear;
  transition: opacity 0.3s ease-in-out;
  ${smallTabletMQ(`
    width: 100%;
    animation: none;
    transition: none;
    margin: 0;
  `)};
`;

const fadeIn = keyframes`
  from {
    transform: scale(0.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.25);
  }
`;

const Container = styled.div<{
  isOpen: boolean;
  isUnderneath: boolean;
}>`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: ${({ isUnderneath }) => isUnderneath ? '100' : '102'};
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  animation: ${({ isOpen }) => isOpen ? fadeIn : fadeOut} 0.3s ease-out;
  transition: visibility 0.3s ease-in-out;
  margin: 12px;
  max-height: 100%;
  ${smallTabletMQ(`
    width: 100%;
    animation: none;
    transition: none;
    margin: 0;
  `)};
`;

export const Content = styled(Card) <{ isFullHeight: boolean }>`
  display: flex;
  padding: 12px 24px 24px 24px;
  max-height: 100%;
  flex-direction: column;

  ${({ isFullHeight }) => isFullHeight && `
    display: flex;
    flex: 1 1 100%;
    height: 100%;
  `};

  ${smallTabletMQ(`
    width: 95%;
    padding: 12px 16px 16px 16px;
    margin: 10px;
  `)};


  ${largeMobileMQ(`
    padding: 10px;
  `)};
`;

const ClickableIcon = styled(Icon) <{ isShown: boolean }>`
${({ isShown }) => isShown ? `
display: none;
` : `
display: flex;
`}
  cursor: pointer;
  margin-right: -5px;
  ${smallTabletMQ(`
    margin-right: 0;
  `)};
`;

const ModalContainer = styled.div`
  ${smallTabletMQ(`
    overflow: auto;
  `)};
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  line-height: 20px;
`;

const FixedHeader = styled.div`
  display: flex;
  flex: 1 1 40px;
  flex-direction: column;
`;

const PaddedRow = styled.div`
  padding-top: 6px;
  place-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Modal: React.FC<IModalProps> = ({
  isOpen, title, handleCloseModal, isFullHeight = false,
  isUnderneath = false, content, isShown = false, className,
}) => {
  React.useEffect(() => {
    if (!isOpen) {
      handleCloseModal();
    }
  }, [isOpen]);

  if (isOpen) {
    return (
      <ModalContainer className={className}>
        <Greyed
          isOpen={isOpen}
          isUnderneath={isUnderneath}
        />

        <Container
          isOpen={isOpen}
          isUnderneath={isUnderneath}
        >
          <Content
            isFullHeight={isFullHeight}
          >
            <FixedHeader>
              <PaddedRow>
                <ModalTitle>
                  {title}
                </ModalTitle>
                <ClickableIcon
                  isShown={isShown}
                  path={x}
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </PaddedRow>
            </FixedHeader>
            <ModalContent>
              {content}
            </ModalContent>
          </Content>
        </Container>
      </ModalContainer>
    );
  }

  return <></>;
};
