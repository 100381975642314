import * as React from 'react';
import styled from 'styled-components';

import { smallTabletMQ } from 'styles/breakpoints';

const IconWrapper = styled.div<{
  appendage?: string;
  appendageFill?: cssHex;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ appendage, appendageFill, theme }) => !!appendage && `
    &:after {
      content: '${appendage}';
      position: absolute;
      height: 18px;
      width: 18px;
      font-size: 14px;
      margin-left: -17px;
      margin-top: -12px;
      border-radius: 50%;
      color: ${theme.colors.white};
      background-color: ${appendageFill ?? theme.colors.yellow[0]};
      text-align: center;
      padding: 3px;
    }
  `}
`;

export const IconContainer = styled.svg<{ fill?: string }>`
  align-items: center;
  justify-content: center;
  display: flex;
  fill: ${({ fill, theme }) => fill ?? theme.colors.black};
  height: ${({ height = 24 }) => height}px;
  min-height: ${({ height = 24 }) => height}px;
  width: ${({ width = 24 }) => width}px;
  min-width: ${({ width = 24 }) => width}px;
  user-select: none;
  transition: fill 0.35s;
  ${smallTabletMQ(`
    height: 24px;
    min-height: 24px
  `)};
  path {
    transition: d 0.35s;
  }
`;

export const Icon: React.FC<IIconProps> = (props) => (
  <IconWrapper
    appendage={props.appendage}
    appendageFill={props.appendageFill}
    className={props.className}
  >
    <IconContainer
      viewBox="0 0 24 24"
      fill={props.fill}
      width={props.width}
      height={props.height}
      onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <path d={props.path}></path>
    </IconContainer>
  </IconWrapper>
);
