/* eslint-disable max-lines */
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate, Script } from 'gatsby';

import { Fade } from 'react-slideshow-image';
import { OrangeTryItButton, TryItButton } from 'components/Button';
import {
  Container,
  TextContainer,
  Blue,
  LightBlue,
  Grey,
  Card,
} from 'components/Containers';
import { Details } from 'components/Details';
import { Seo } from 'components/Seo';
import {
  CenteredDetails,
  CenteredWhiteHeader,
  CenteredWhiteDetails,
  BlueDetails,
} from 'components/Text';
import { NavBar } from 'components/NavBar';
import { Modal } from 'components/Modal';
import { IFrame } from 'components/IFrame';
import { Footer } from 'components/Footer';

import SimpleIcon from 'assets/svgs/SimpleIcon.svg';
import SecureIcon from 'assets/svgs/SecureIcon.svg';
import ConnectedIcon from 'assets/svgs/ConnectedIcon.svg';
import Calculator from 'assets/svgs/index-calculator.svg';
import Cash from 'assets/svgs/index-cash.svg';
import Calendar from 'assets/svgs/index-calendar.svg';
import Invoice from 'assets/svgs/index-invoice.svg';
import MelanieQuote from 'assets/svgs/index-melanie-quote.svg';
import MelanieQuoteMobile from 'assets/svgs/index-melanie-quote-mobile.svg';
import StoneCityWorksQuote from 'assets/svgs/index-stonecitywoodworks-quote.svg';
import StoneCityWorksQuoteMobile from 'assets/svgs/index-stonecitywoodworks-quote-mobile.svg';

import {
  extraLargeDesktopMQ,
  extraLargeMobileMQ,
  largeDesktopMQ,
  largeMobileMQ,
  largeTabletMQ,
  mediumDesktopMQ,
  smallDesktopMQ,
  smallMobileMQ,
  smallTabletMQ,
} from 'styles/breakpoints';
import referrals from 'api/referrals';

const SpaceBetweenRow = styled(Container)`
  display: flex;
  justify-content: space-between;
  ${smallTabletMQ(`
    flex-direction: column;
  `)}
  position: relative;
`;

const DemoImageContainer = styled.div`
  display: flex;
  height: 900px;
  ${extraLargeDesktopMQ(`
    height: 700px;
  `)}
  ${largeTabletMQ(`
    height: 800px;
  `)}
  position: relative;
  justify-content: flex-end;
  img {
    border-radius: unset;
    position: absolute;
  }
  ${smallTabletMQ(`
    flex-direction: column;
    height: unset;
  `)}
  ${({ theme }) => smallTabletMQ(`background-color: ${theme.colors.blue[0]}`)}
  .backgroundImage {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const QuoteContainer = styled.div`
  ${smallTabletMQ(`
    margin-bottom: 50px;
    width: 100%;
  `)}
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

`;

const MelanieQuoteStyled = styled(MelanieQuote)`
  position: absolute;
  top: -30px;
  left: -70%;
  z-index: 99;
  ${largeDesktopMQ(`
    width: 300px;
    left: -90%;
  `)}
  ${mediumDesktopMQ(`
    width: 250px;
  `)}
  ${smallDesktopMQ(`
    width: 200px;
  `)}
  ${largeTabletMQ(`
    width: 200px;
  `)}
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const StoneCityWorksQuoteStyled = styled(StoneCityWorksQuote)`
  position: absolute;
  top: -30px;
  left: -65%;
  z-index: 99;
  ${largeDesktopMQ(`
    left: -100%;
    width: 300px;
  `)}
  ${mediumDesktopMQ(`
    width: 250px;
  `)}
  ${smallDesktopMQ(`
    width: 200px;
  `)}
  ${largeTabletMQ(`
    width: 200px;
  `)}
  ${smallTabletMQ(`
    display: none;
  `)}
`;
const MelanieQuoteStyledMobile = styled(MelanieQuoteMobile)`
  position: absolute;
  z-index: 99;
  display: none;
  left: -90%;
  top: -40px;
  ${smallTabletMQ(`
    display: flex;
    left: -200%;
    width: 400px;
  `)}
  ${largeMobileMQ(`
    width: 200px;
    left: -100%;
  `)}
`;

const StoneCityWorksQuoteStyledMobile = styled(StoneCityWorksQuoteMobile)`
  position: absolute;
  z-index: 99;
  display: none;
  left: -90%;
  top: -40px;
  ${smallTabletMQ(`
    display: flex;
    left: -200%;
    width: 400px;
  `)}
  ${largeMobileMQ(`
    width: 200px;
    left: -100%;
  `)}
`;


const MainImageContainer = styled(DemoImageContainer)`
  height: 700px;
  ${largeDesktopMQ(`
    height: 500px;
  `)}
  display: flex;
  align-items: center;
  justify-content: center;
  ${smallTabletMQ(`
    height: unset;
  `)}
`;

const DemoSide = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-left: 15px;
  justify-content: space-evenly;
  z-index: 2;
  > * {
    z-index: 2;
    position: relative;
  }
  box-sizing: border-box;

  ${smallTabletMQ(`
    width: 100%;
    height: unset;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  `)}
`;

const DemoContainer = styled(Container)`
  width: 50%;
  ${largeDesktopMQ(`
    width: 100%;
  `)}
  justify-content: flex-end;
  ${smallTabletMQ(`
    padding: 0;
  `)}
  margin-left: 0;
`;

const GreyDemoSide = styled(DemoSide)`
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.rgba[0]};
  width: 50%;
  ${smallDesktopMQ(`
    width: 60%;
  `)}
  ${largeTabletMQ(`
    width: 70%;
  `)}
  ${smallTabletMQ(`
    position: unset;
    width: 100%;
  `)}
`;

const Gradient = styled(GreyDemoSide)`
  width: 100%;
  background-color: unset;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.rgba[2]} 40%,
    transparent
  );
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const FlexDiv = styled.div`
  flex: 1;
`;

const DesktopVideoContainer = styled.div`
  flex: 1;
  ${smallTabletMQ(`
    display: none;
  `)}
`;

const MainContainer = styled.div`
  z-index: 70;
  width: 100%;
  ${({ theme }) => smallTabletMQ(`
    margin-top: 50vw;
    background-image: linear-gradient(
      to bottom,
      ${theme.colors.rgba[3]} 0%,
      ${theme.colors.rgba[2]}
    );
  `)}
  ${largeMobileMQ(`
    margin-top: 55vw;
  `)}
`;


const ReverseContainer = styled(Container)`
  ${smallTabletMQ(`
    flex-direction: column-reverse;
    padding-top: 30px;
    padding-bottom: 30px;
  `)}
`;

const DetailsContainer = styled.div`
  display: flex;
  padding-bottom: 40px;
  justify-content: flex-end;
  ${smallTabletMQ(`
    padding-bottom: 20px;
  `)}
`;

const LastDetailsContainer = styled(DetailsContainer)`
  padding-bottom: 0;
  ${smallTabletMQ(`
    padding-bottom: 20px;
  `)}
`;

const MobileBlueDetails = styled(BlueDetails)`
  ${smallTabletMQ(`
    display: flex;
    `)}

  display: none;
  text-align: center;
`;

const ListContainer = styled.div`
  ${smallTabletMQ(`
    width: 100%;
    align-items: start;
  `)}
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ColContainer = styled(Container)`
  flex-direction: column;
  ${smallTabletMQ(`
    padding-top: 30px;
    padding-bottom: 30px;
  `)}
`;

const FlexDiv2 = styled(FlexDiv)`
  width: 350px;
  ${largeDesktopMQ(`
    width: 250px;
  `)}
  ${mediumDesktopMQ(`
    width: 200px;
  `)}
  ${smallDesktopMQ(`
    width: 150px;
  `)}
`;

const ColDemoContainer = styled(ColContainer)`
  margin-left: 0;
`;

const MarginCenteredWhiteHeader = styled(CenteredWhiteHeader)`
  margin-bottom: 30px;
`;

const YourBudContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${smallTabletMQ(`
    width: 100%;
  `)}
`;

const MarginBudContainer = styled(YourBudContainer)`
  margin-bottom: 20px;
`;

const BorderRight = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.blue[3]};
  flex: 1;
  margin: 0 10px;
  ${smallMobileMQ(`
    display: none;
  `)}
`;

const BorderRightInvisible = styled(BorderRight)`
  border-bottom: 2px solid transparent;
`;

const StyledTextContainer = styled(TextContainer)`
  min-width: 380px;
  ${smallTabletMQ(`
    min-width: unset;
  `)}
`;

const IconContainer = styled.div`
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${smallTabletMQ(`
    min-width: 80px;
    max-width: 80px;
  `)}
  ${largeMobileMQ(`
    min-width: 60px;
    max-width: 60px;
    margin: 0 10px;
    svg {
      width: 30px;
    }
  `)}
  ${smallMobileMQ(`
    margin: 0 10px;
  `)}
`;
const FirstPictureContainer = styled.div`
  ${smallTabletMQ(`
    flex-direction: column;
  `)}
  justify-content: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  width: 100%;
`;

const RadialGradient = styled(Blue)`
  /* radial gradient light grey very low opacity */
  /* light grey at 10% opacity */
  background-image: radial-gradient(
    circle at 50% 100%,
    rgba(243, 244, 246, 0.04) 50%,
    transparent
  );
  ${smallTabletMQ(`
    background-image: radial-gradient(
      circle at 75% 100%,
      rgba(243, 244, 246, 0.08) 20%,
      transparent
    );
  `)}
`;

const RadialGradient2 = styled(RadialGradient)`
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(243, 244, 246, 0.06) 15%,
    transparent
  );
  ${smallTabletMQ(`
    background-image: radial-gradient(
      circle at 50% 100%,
      rgba(243, 244, 246, 0.06) 20%,
      transparent
    );
  `)}
`;

const CenteredButtonContainer = styled.div`
  padding-top: 30px;
  text-align: center;
`;

const HomeDetails = styled.h3`
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
  ${largeDesktopMQ(`
    font-size: 18px;
  `)}
  ${smallTabletMQ(`
    margin: 10px 0;
    font-size: 16px;
  `)}
  color: ${({ theme }) => theme.colors.blue[0]};
`;

const WidthDetails = styled(Details)`
  width: 100%;
`;

const CenterTextWhite = styled(HomeDetails)`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 0;
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${smallTabletMQ(`
    min-width: 80px;
    max-width: 80px;
  `)}
  ${extraLargeMobileMQ(`
    font-size: 14px;
  `)}
  ${largeMobileMQ(`
    min-width: 60px;
    max-width: 60px;
    margin: 0 10px;
  `)}
`;

const ImageContainer = styled.div`
  img {
    ${smallTabletMQ(`
      top: -104px !important;
      height: calc(100% + 104px) !important;
    `)}
    ${largeMobileMQ(`
      top: -134px !important;
      height: calc(100% + 134px) !important;
    `)}
  }
`;

const ImageContainerBeam = styled.div`
  width: 50%;
  ${largeDesktopMQ(`
    width: 100% !important;
  `)}
  .gatsby-image-wrapper {
    position: relative !important;
    ${largeDesktopMQ(`
      position: absolute !important;
    `)}
    ${smallTabletMQ(`
      position: relative !important;
      height: 300px !important;
    `)}

    ${largeMobileMQ(`
      height: 200px !important;
    `)}
  }
  img {
    ${smallTabletMQ(`
      top: -104px !important;
      height: calc(100% + 104px) !important;
    `)}
    ${largeMobileMQ(`
      height: calc(100% + 134px) !important;
    `)}
  }
`;

const MobileVideoContainer = styled(Container)`
  display: none;
  background-color: ${({ theme }) => theme.colors.grey[3]};
  padding-top: 10px;
  padding-bottom: 10px;
  ${smallTabletMQ(`
    display: block;
  `)}
`;

const TextContainer1 = styled(TextContainer)`
  display: flex;
  `;

const Simple = styled(SimpleIcon)`
  margin-top: -7px;
  ${smallTabletMQ(`
    width: 30px;
  `)}
`;

const Connected = styled(ConnectedIcon)`
  margin-top: -10px;
  ${smallTabletMQ(`
    width: 30px;
  `)}
`;

const Secure = styled(SecureIcon)`
  margin-top: -5px;
  ${smallTabletMQ(`
    width: 30px;
  `)}
`;

const CalculatorIcon = styled(Calculator)`
  ${smallTabletMQ(`
    margin-left: 5px;
  `)}
`;

const Meeting = styled.div`
  min-width: 865px;
  z-index: 30;

  iframe {
    height: 675px !important;
  }
  ${largeTabletMQ(`

    min-width: 700px;
  `)}
  ${smallTabletMQ(`
    min-width: unset;
    iframe {
      height: 650px !important;
    }
  `)}
`;

const StyledModal = styled(Modal)`
  ${smallMobileMQ(`
    padding: 0;

    ${Card} {
      padding: 0;
    }
  `)}
`;

const WordContainer = styled.div`
  align-self: end;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .react-slideshow-fadezoom-wrapper {
    overflow: unset;
  }
  button {
    display: none !important;
  }
  ${FlexDiv2} {
    margin-right: 10px;
    ${smallTabletMQ(`
      margin-right: 20px;
    `)}
    ${smallMobileMQ(`
      margin-right: 10px;
    `)}
  }
`;


const Home = () => {
  const theme = useTheme();
  const urlSearchParams = typeof window !== 'undefined'
    ? new URLSearchParams(window.location.search) as IGenericObject : undefined;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const referral = urlSearchParams
    // eslint-disable-next-line
      ? Object.fromEntries(urlSearchParams.entries())?.a : undefined as string | undefined;

  // Make a request to our backend to store the referral
  const createReferral = async(token?: string) => {
    await referrals.post({
      companyName: 'Electrical Contractors Magazine',
      slug: 'ecm',
      recaptchaToken: token,
    });
  };

  React.useEffect(() => {
    window.history.replaceState({}, '', `${window.location.pathname}`);
    // If there is a referral query param, we need google recaptcha to verify that
    // its not a bot. After that, we can store the referral in our database
    if (referral) {
      const script = document.createElement('script');
      script.src =
        `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY}`;
      document.body.appendChild(script);
      script.addEventListener('load', loadRecaptcha);
    }
  }, [referral]);

  const loadRecaptcha = () => {
    if (referral && window.grecaptcha) {
      // clear the pathname ?a= query param
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.grecaptcha.ready(() => {
        // eslint-disable-next-line
        window.grecaptcha.execute(process.env.RECAPTCHA_KEY, { action: 'signup' })
          // eslint-disable-next-line @typescript-eslint/require-await
          .then(async(token?: string) => {
            // If its an electical contractor magazine referral, store it
            if (referral === 'ecm') {
              // eslint-disable-next-line no-void
              void createReferral(token);
            }
          }, () => {
            //
          });
      });
    }
  };

  const renderModals  = () => (
    <StyledModal
      title=""
      isOpen={isModalOpen}
      handleCloseModal={() => { setIsModalOpen(false); }}
      content={(
        <>
          {/* @ts-expect-error */}
          <Script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js">
          </Script>
          <Meeting
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/nicolas-good?embed=true">
          </Meeting>
        </>
      )}
    />
  );

  return (
    <>
      <NavBar />
      {renderModals()}
      <Seo
        title="WireBuddy - Save time, get organized, get paid faster."
        description="Save time, get organized, get paid faster."
        lang="en"
        image="src/assets/svgs/WireBuddyLogoPartial.svg"
      />
      <MainImageContainer>
        <ImageContainer>
          <StaticImage
            alt="index-man-ceiling.jpg"
            src="../images/index-man-ceiling.jpg"
            className="backgroundImage"
            loading="eager"
            placeholder='blurred'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
            quality={100}
          />
        </ImageContainer>
        <Gradient />
        <MainContainer>
          <Container>
            <FirstPictureContainer>
              <StyledTextContainer>
                <h1>
                All your jobs, <br />simply in one place
                </h1>

                <HomeDetails>
                  Manage your contracting business and customer relationships in our
                  easy to use app.
                </HomeDetails>

                <TryItButton onClick={async() => navigate('/signup')}>
                  Try it for free
                </TryItButton>
              </StyledTextContainer>
              <DesktopVideoContainer>
                <IFrame
                  // eslint-disable-next-line max-len
                  src="https://player.vimeo.com/video/749392218?h=9f02f8b565&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                />
              </DesktopVideoContainer>
            </FirstPictureContainer>
          </Container>
        </MainContainer>
      </MainImageContainer>
      <MobileVideoContainer>
        <IFrame
          // eslint-disable-next-line max-len
          src="https://player.vimeo.com/video/749392218?h=9f02f8b565&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        />
      </MobileVideoContainer>
      <RadialGradient>
        <ColContainer>
          <MarginCenteredWhiteHeader>Your bud from quote to cash</MarginCenteredWhiteHeader>
          <YourBudContainer>
            <IconContainer>
              <CalculatorIcon />
            </IconContainer>
            <BorderRightInvisible />
            <IconContainer>
              <Calendar />
            </IconContainer>
            <BorderRightInvisible />
            <IconContainer>
              <Invoice />
            </IconContainer>
            <BorderRightInvisible />
            <IconContainer>
              <Cash />
            </IconContainer>
          </YourBudContainer>
          <MarginBudContainer>
            <CenterTextWhite>Send<br />Quotes</CenterTextWhite>
            <BorderRight />
            <CenterTextWhite>Schedule<br />Jobs</CenterTextWhite>
            <BorderRight />
            <CenterTextWhite>Generate<br />Invoices</CenterTextWhite>
            <BorderRight />
            <CenterTextWhite>Collect<br />Payments</CenterTextWhite>
          </MarginBudContainer>
          <CenteredWhiteDetails>
            Take our quick tour and learn how WireBuddy can help your business.
          </CenteredWhiteDetails>
          <CenteredDetails>
            <OrangeTryItButton onClick={async() => navigate('/tour')}>
              Learn More
            </OrangeTryItButton>
          </CenteredDetails>
        </ColContainer>
      </RadialGradient>
      <Grey>
        <ReverseContainer>
          <TextContainer1>
            <FlexDiv2>
              <Fade
                indicators={() => (<div className="indicator" />)}
                transitionDuration={500}
                canSwipe
                duration={7000}
              >
                <StaticImage
                  alt="WireBuddy app"
                  src="../images/index-quote.png"
                  placeholder='blurred'
                  loading='eager'
                />

                <StaticImage
                  alt="WireBuddy app"
                  src="../images/index-schedule.png"
                  placeholder='blurred'
                />
                <StaticImage
                  alt="WireBuddy app"
                  src="../images/index-billing.png"
                  placeholder='blurred'
                />
              </Fade>

            </FlexDiv2>
          </TextContainer1>

          <ListContainer>

            <DetailsContainer>
              <Simple/>
              <WidthDetails
                name="Efficient"
                details={
                  'WireBuddy’s simple and automated system helps you' +
                  ' avoid wasting hours writing out quotes and invoices.'
                }
              />
            </DetailsContainer>

            <DetailsContainer>
              <Connected/>
              <WidthDetails
                name="Unified"
                details={
                  'Create quotes, schedule jobs, send invoices, ' +
                  'and collect payments, all with one tool.'
                }
              />
            </DetailsContainer>

            <LastDetailsContainer>
              <Secure/>
              <WidthDetails
                name="Secure"
                details={
                  'WireBuddy is the first encrypted contractor tool to ' +
                  'give you complete power over and ownership of your data.'
                }
              />
            </LastDetailsContainer>

          </ListContainer>

        </ReverseContainer>

      </Grey>
      <LightBlue>
        <SpaceBetweenRow>
          <QuoteContainer>
            <h1>Don't just take our<br/> word for it</h1>
            <BlueDetails>Check out what others in the industry are saying.</BlueDetails>
          </QuoteContainer>
          <WordContainer>

            <FlexDiv2>
              <Fade
                transitionDuration={500}
                duration={7000}
                infinite
              >
                <a
                  href="https://www.tiktok.com/@thatfemalefarmer/video/7240611515328367877"
                  target="_blank"
                  rel="noreferrer"
                >
                  <MelanieQuoteStyled />
                  <MelanieQuoteStyledMobile />
                  <StaticImage
                    alt="index-melanie-the-farmer.png"
                    src="../images/index-melanie-the-farmer.png"
                    className="backgroundImage"
                    loading="eager"
                    placeholder='blurred'
                    quality={100}
                    style={{
                      border: `4px solid ${theme.colors.blue[0]}`,
                      borderRadius: '18px',
                    }}
                  />
                  <MobileBlueDetails>@thatfemalefarmer</MobileBlueDetails>
                </a>
                <a
                  href="https://www.tiktok.com/@stonecitywoodworks/video/7246748724205243654"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StoneCityWorksQuoteStyled />
                  <StoneCityWorksQuoteStyledMobile />
                  <StaticImage
                    alt="index-stonecitywoodworks"
                    src="../images/index-stonecitywoodworks.png"
                    className="backgroundImage"
                    loading="eager"
                    placeholder='blurred'
                    quality={100}
                    style={{
                      border: `4px solid ${theme.colors.blue[0]}`,
                      borderRadius: '18px',
                    }}
                  />
                  <MobileBlueDetails>@stonecitywoodworks</MobileBlueDetails>
                </a>
              </Fade>

            </FlexDiv2>
          </WordContainer>
        </SpaceBetweenRow>
      </LightBlue>
      <RadialGradient2>
        <ColContainer>
          <CenteredWhiteHeader>
            Pricing
          </CenteredWhiteHeader>
          <CenteredWhiteDetails>
            Our affordable pricing is unmatched
          </CenteredWhiteDetails>
          <CenteredButtonContainer>
            <OrangeTryItButton onClick={async() => navigate('/pricing')}>
              View Pricing
            </OrangeTryItButton>
          </CenteredButtonContainer>
        </ColContainer>
      </RadialGradient2>
      <DemoImageContainer>
        <ImageContainerBeam>
          <StaticImage
            alt="index-guy-on-beam"
            src="../images/index-guy-on-beam.jpg"
            className="backgroundImage"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
            }}
            placeholder='blurred'
          />
        </ImageContainerBeam>
        <DemoContainer>
          <DemoSide />
          <GreyDemoSide>
            <ColDemoContainer>
              <h1>Still not convinced? <br/>Book a demo</h1>
              <HomeDetails>
                Let us show you around WireBuddy and how it will save you time.
              </HomeDetails>
              <OrangeTryItButton
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >Book a Demo</OrangeTryItButton>
            </ColDemoContainer>
          </GreyDemoSide>
        </DemoContainer>
      </DemoImageContainer>
      <Footer />
      <div
        className="g-recaptcha"
        data-sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        data-size="invisible"
      ></div>
    </>
  );
};

export default Home;
