import React from 'react';
import styled from 'styled-components';

import { smallTabletMQ } from 'styles/breakpoints';
import { Icon } from 'components/Icon';

const DetailsContainer = styled.div`
  margin-bottom: 10px;
`;

const DetailName = styled.h2<{
  fill?: cssHex;
  needsMargin: boolean;
}>`
  padding-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  color: ${({ theme, fill }) => fill ?? theme.colors.blue[0]};
  ${({ needsMargin }) => needsMargin ? 'margin-top: 15px;' : ''};
  ${smallTabletMQ(`
    padding: 0;
  `)}
`;

const DetailsContent = styled.h3<{ fill?: cssHex }>`
  max-width: 380px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: ${({ theme, fill }) => fill ?? theme.colors.blue[0]};
  ${smallTabletMQ(`
    max-width: unset;
  `)}
`;

const ContentContainer = styled.div`
  display: flex;
  padding-left: 10px;
  ${smallTabletMQ(`
    max-width: unset;
  `)}
`;

export const Details = ({
  name = 'Simple',
  details = '',
  fill,
  className,
  Svg,
  icon,
  iconFill,
}: IDetailsProps) => (
  <ContentContainer className={className}>
    <DetailsContainer>
      {!!Svg && <Svg />}
      {icon && <Icon path={icon} fill={iconFill}/>}
      <DetailName fill={fill} needsMargin={!!icon || !!Svg}>
        {name}
      </DetailName>

      <DetailsContent fill={fill}>
        {details}
      </DetailsContent>
    </DetailsContainer>
  </ContentContainer>
);
